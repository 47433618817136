import React, { useState } from 'react';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { addAccomplishment } from '../../firebase';
import {
  Alert,
  Box,
  Button,
  ImageList,
  ImageListItem,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import * as moment from 'moment';

const fileTypes = ['JPG', 'PNG', 'GIF'];

const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const AdminPage = () => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!cover || !images) {
      setErrorWording('กรุณากรอกข้อมูลให้ครบ');
      setOpenError(true);
    } else {
      setLoading(true);
      const storage = getStorage();
      const coverRef = ref(
        storage,
        `accomplishment/${moment().unix()}${cover.name}`,
      );
      const coverMeta = await uploadBytes(coverRef, cover);
      const tmp = [];
      [...images].forEach(async (file) => {
        const tmpRef = ref(
          storage,
          `accomplishment/${moment().unix()}${file.name}`,
        );
        tmp.push(uploadBytes(tmpRef, file));
      });
      const imagesMeta = await Promise.all(tmp);
      const imagesURL = await Promise.all(
        imagesMeta.map((image) => getDownloadURL(image.metadata.ref)),
      );
      addAccomplishment({
        EN: {
          name: data.get('nameEN'),
          location: data.get('locationEN'),
          description: data.get('descriptionEN'),
        },
        TH: {
          name: data.get('nameTH'),
          location: data.get('locationTH'),
          description: data.get('descriptionTH'),
        },
        image: {
          cover: (await getDownloadURL(coverMeta.metadata.ref)).toString(),
          references: imagesURL,
        },
        timestamp: moment().unix(),
      })
        .then(() => {
          setLoading(false);
          setOpenSuccess(true);
          setCover(null);
          setImages(null);
          setCoverPreview('');
          setImagesPreview([]);
          document.getElementById('refForm').reset();
        })
        .catch(() => {
          setLoading(false);
          setErrorWording('เกิดข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ');
          setOpenError(true);
        });
    }
  };
  const [cover, setCover] = useState(null);
  const [images, setImages] = useState(null);
  const [coverPreview, setCoverPreview] = useState('');
  const [imagesPreview, setImagesPreview] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorWording, setErrorWording] = useState('');
  const handleChangeCover = async (file) => {
    setCover(file);
    setCoverPreview(await fileToBase64(file));
  };
  const handleChangeImages = async (files) => {
    setImages(files);
    const tmp = [];
    [...files].forEach(async (file) => {
      tmp.push(fileToBase64(file));
    });
    setImagesPreview(await Promise.all(tmp));
  };
  return (
    <React.Fragment>
      <section id="hero_in" className="contacts">
        <div className="wrapper">
          <div className="container">
            <h1 className="fadeInUp">
              Admin<span style={{ marginTop: '20px' }}></span>
            </h1>
          </div>
        </div>
      </section>
      <div className="container">
        <Box
          component="form"
          id="refForm"
          onSubmit={handleSubmit}
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="nameTH"
            label="ชื่อโครงการ(TH)"
            name="nameTH"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="nameEN"
            label="ชื่อโครงการ(EN)"
            name="nameEN"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="locationTH"
            label="สถานที่ตั้ง(TH)"
            name="locationTH"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="locationEN"
            label="สถานที่ตั้ง(EN)"
            name="locationEN"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="descriptionTH"
            label="คำอธิบาย(TH)"
            name="descriptionTH"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="descriptionEN"
            label="คำอธิบาย(EN)"
            name="descriptionEN"
          />
          <Typography>รูปปก</Typography>
          <FileUploader
            handleChange={handleChangeCover}
            name="file"
            types={fileTypes}
          />
          {coverPreview && <img alt="" src={coverPreview} />}
          <Typography>รูปผลงาน</Typography>
          <FileUploader
            handleChange={handleChangeImages}
            name="file"
            multiple={true}
            types={fileTypes}
          />
          {imagesPreview.length ? (
            <ImageList
              sx={{ width: 500, height: 450 }}
              cols={3}
              rowHeight={164}
            >
              {imagesPreview.map((item, index) => (
                <ImageListItem key={index}>
                  <img
                    src={`${item}`}
                    srcSet={`${item}`}
                    alt={index}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            ''
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={() => setOpenError(false)}
        >
          <Alert
            variant={'filled'}
            onClose={() => {
              setOpenError(false);
            }}
            severity="error"
            sx={{ width: '100%' }}
          >
            {errorWording}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={() => setOpenSuccess(false)}
        >
          <Alert
            variant={'filled'}
            onClose={() => {
              setOpenSuccess(false);
            }}
            severity="success"
            sx={{ width: '100%' }}
          >
            บันทึกข้อมูลสำเร็จ
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
};
