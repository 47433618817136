import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { languages } from '../../app.const';
import { ImagePreview } from '../../components/imagePreview/ImagePreview';
import { getAccomplishmentByNamePromise } from '../../firebase';

export const ShowPage = () => {
  const [content, setContent] = useState({
    name: '',
    location: '',
    imageList: [],
    thumbnail: '',
  });
  const { name } = useParams();
  useEffect(() => {
    getAccomplishmentByNamePromise(name).then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        setContent(doc.data());
      });
    });
  }, [name]);
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || languages.TH;
  return (
    <React.Fragment>
      <section id="hero_in" className="contacts">
        <div className="wrapper">
          <div className="container">
            <h1 className="fadeInUp">
              Showcase Glazier<span style={{ marginTop: '20px' }}></span>
            </h1>
          </div>
        </div>
      </section>
      <div className="container" style={{ marginTop: '15px' }}>
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            style={{ marginBottom: '15px' }}
          >
            <table>
              <tbody>
                <tr style={{ borderBottom: '1px solid rgb(56 94 29 / 50%)' }}>
                  <td style={{ widht: '100px' }}>{'ชื่อโครงการ:'}</td>
                  <td>{content[lang]?.name}</td>
                </tr>
                <tr style={{ borderBottom: '1px solid rgb(56 94 29 / 50%)' }}>
                  <td>{'สถานที่ตั้ง:'}</td>
                  <td>{content[lang]?.location}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <ImagePreview
            imageList={content.image?.references?.map((ref) => ({
              original: ref,
            }))}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
