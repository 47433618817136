import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { languages } from '../../app.const';
import { label } from '../../label';
import './HomePage.css';

export const HomePage = () => {
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || languages.TH;

  return (
    <div className='home-page'>
      <section className="hero_single">
        <div className="wrapper">
          <div className="container">
            {/* <h3>
              <strong>{label.homepage.h1[lang]}</strong>
              <br />
              {label.homepage.h2[lang]}
            </h3>
            <p>
              <strong>{label.homepage.h3[lang]}</strong>
            </p> */}
          </div>
        </div>
      </section>
    </div>
  );
};
