import React from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import { languages } from '../../app.const';
import { label } from '../../label';
import './ServicePage.css';

export const ServicePage = () => {
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || languages.TH;
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <section id="hero_in" className="service">
        <div className="wrapper">
          <div className="container">
            <h1 className="fadeInUp">
              Products & Services<span style={{ marginTop: '20px' }}></span>
            </h1>
          </div>
        </div>
      </section>
      <div className="container">
        <Slider
          {...settings}
          style={{ marginBottom: '50px', marginTop: '20px' }}
        >
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(`/services/curtain-wall?lang=${lang}`);
                }}
              >
                <img
                  src={'/img/category/Curtain-Wall.png'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">
                  {label.services.curtainWall[lang]}
                </span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(`/services/fixed-panel?lang=${lang}`);
                }}
              >
                <img
                  src={'/img/category/Fixed-Panel.png'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">
                  {label.services.fixedPanel[lang]}
                </span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(
                    `/services/awing-casement-door-and-window?lang=${lang}`,
                  );
                }}
              >
                <img
                  src={'/img/category/AwingCasement-Door-and-Window.png'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">
                  {label.services.awingCasement[lang]}
                </span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(`/services/sliding-door-and-window?lang=${lang}`);
                }}
              >
                <img
                  src={'/img/category/Sliding-Door-and-Window.png'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">{label.services.sliding[lang]}</span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(`/services/swing-door?lang=${lang}`);
                }}
              >
                <img
                  src={'/img/category/Swing-Door.png'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">{label.services.swing[lang]}</span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(`/services/frameless-door-and-window?lang=${lang}`);
                }}
              >
                <img
                  src={'/img/category/Frameless-Door-and-Window.png'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">
                  {label.services.frameless[lang]}
                </span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(`/services/louvre?lang=${lang}`);
                }}
              >
                <img
                  src={'/img/category/Louvre.png'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">{label.services.louvre[lang]}</span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(`/services/aluminium-composite?lang=${lang}`);
                }}
              >
                <img
                  src={'/img/category/Automatic-DoorWindow.png'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">
                  {label.services.aluminium[lang]}
                </span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(`/services/railing?lang=${lang}`);
                }}
              >
                <img
                  src={'/img/category/Railing.jpg'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">
                  {label.services.railing[lang]}
                </span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(`/services/canopy?lang=${lang}`);
                }}
              >
                <img
                  src={'/img/category/Canopy.jpg'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">
                  {label.services.canopy[lang]}
                </span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ position: 'relative', padding: '10px' }}>
              <div
                className="image-cropper"
                onClick={() => {
                  navigate(`/services/other?lang=${lang}`);
                }}
              >
                <img
                  src={'/img/category/other.jpg'}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
                <span className="centered">
                  {label.services.other[lang]}
                </span>
                <div className="image-dimmer"></div>
              </div>
            </div>
          </div>
        </Slider>
        <Outlet />
      </div>
    </React.Fragment>
  );
};
