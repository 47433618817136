import React from 'react';
import GoogleMapReact from 'google-map-react';
import { useSearchParams } from 'react-router-dom';
import { languages } from '../../app.const';
import { label } from '../../label';
import { Card } from '@mui/material';
import './ContactPage.css';
import { useEffect } from 'react';

export const ContactPage = () => {
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || languages.TH;
  const contactList = [
    {
      icon: (
        <i className="icon-location-circled" style={{ color: '#385E1D' }} />
      ),
      description: label.contact.location[lang],
    },
    {
      icon: <i className="icon-phone-squared" style={{ color: '#385E1D' }} />,
      description: label.contact.phone[lang],
    },
    {
      icon: <i className="icon-print-2" style={{ color: '#385E1D' }} />,
      description: 'Fax: 02-510-9007',
    },
    {
      icon: <i className="icon-mail-circled" style={{ color: '#385E1D' }} />,
      description: 'ht.lucky24@gmail.com',
    },
  ];

  const tableRow = (data, index) => (
    <tr key={index}>
      <td
        style={{ verticalAlign: 'baseline', padding: '10px', fontSize: '36px' }}
      >
        {data.icon}
      </td>
      <td style={{ whiteSpace: 'pre-wrap', padding: '10px', fontSize: '16px' }}>
        {data.description}
      </td>
    </tr>
  );

  const defaultProps = {
    center: {
      lat: 13.8513268,
      lng: 100.6318203,
    },
    zoom: 15,
  };
  useEffect(() => {
    window.addEventListener('resize', () => {
      const newWidth = document.getElementById('map').offsetWidth / 2 - 20;
      document.getElementsByClassName(
        'card-resize',
      )[0].style.left = `-${newWidth}px`;
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      const newWidth = document.getElementById('map').offsetWidth / 2 - 20;
      document.getElementsByClassName(
        'card-resize',
      )[0].style.left = `-${newWidth}px`;
    }, 500);
  });
  useEffect(() => {
    return () => {
      window.removeEventListener('resize', () => {
        const newWidth = document.getElementById('map').offsetWidth / 2 - 20;
        document.getElementsByClassName(
          'card-resize',
        )[0].style.left = `-${newWidth}px`;
      });
    };
  }, []);

  return (
    <React.Fragment>
      <section id="hero_in" className="contacts">
        <div className="wrapper">
          <div className="container">
            <h1 className="fadeInUp">
              contact us<span style={{ marginTop: '20px' }}></span>
            </h1>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 hide-tablet">
            <div style={{ height: '500px', width: '100%', margin: '30px 0px' }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyDuldVq7qTsoWPbfAuAlGxxMRE5S_Y7LZI',
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <div
                  className="icon-location-7"
                  style={{
                    fontSize: '30px',
                    color: 'red',
                    marginLeft: '-15px',
                    marginTop: '-15px',
                  }}
                  lat={defaultProps.center.lat}
                  lng={defaultProps.center.lng}
                />
                <Card
                  classes={{ root: 'open-map-card' }}
                  onClick={() => {
                    window.open(
                      'https://www.google.co.th/maps/place/%E0%B9%84%E0%B8%AE%E0%B9%80%E0%B8%97%E0%B8%84+%E0%B9%80%E0%B8%81%E0%B8%A5%E0%B8%8B%E0%B9%80%E0%B8%8B%E0%B8%AD%E0%B8%A3%E0%B9%8C/@13.8513268,100.6318203,17z/data=!4m5!3m4!1s0x311d638b573b8c29:0x3a927e92a55544cc!8m2!3d13.8513268!4d100.6318203?hl=th',
                      '_blank',
                    );
                  }}
                >
                  open on google map
                </Card>
              </GoogleMapReact>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
            <table
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '20px',
              }}
            >
              <tbody>
                {contactList.map((data, index) => tableRow(data, index))}
                <tr>
                  <td
                    style={{
                      verticalAlign: 'baseline',
                      padding: '10px',
                      fontSize: '36px',
                    }}
                  >
                    <i
                      className="icon-instagramm"
                      style={{ color: '#385E1D' }}
                    />
                  </td>
                  <td
                    style={{
                      whiteSpace: 'pre-wrap',
                      padding: '10px',
                      fontSize: '16px',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      window.open(
                        'https://www.instagram.com/hitechglazier/',
                        '_blank',
                      );
                    }}
                  >
                    hitechglazier
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 show-tablet">
            <div
              style={{ height: '500px', width: '100%', margin: '30px 0px' }}
              id="map"
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyDuldVq7qTsoWPbfAuAlGxxMRE5S_Y7LZI',
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <div
                  className="icon-location-7"
                  style={{
                    fontSize: '30px',
                    color: 'red',
                    marginLeft: '-15px',
                    marginTop: '-15px',
                  }}
                  lat={defaultProps.center.lat}
                  lng={defaultProps.center.lng}
                />
                <Card
                  classes={{ root: 'open-map-card card-resize' }}
                  onClick={() => {
                    window.open(
                      'https://www.google.co.th/maps/place/%E0%B9%84%E0%B8%AE%E0%B9%80%E0%B8%97%E0%B8%84+%E0%B9%80%E0%B8%81%E0%B8%A5%E0%B8%8B%E0%B9%80%E0%B8%8B%E0%B8%AD%E0%B8%A3%E0%B9%8C/@13.8513268,100.6318203,17z/data=!4m5!3m4!1s0x311d638b573b8c29:0x3a927e92a55544cc!8m2!3d13.8513268!4d100.6318203?hl=th',
                      '_blank',
                    );
                  }}
                >
                  open on google map
                </Card>
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
