import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { languages } from '../../app.const';

import { getAccomplishmentPromise } from '../../firebase';

export const AccomplishmentPage = () => {
  const [accomplishments, setAccomplishments] = useState([]);
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || languages.TH;
  useEffect(() => {
    getAccomplishmentPromise().then(function (querySnapshot) {
      const tmp = [];
      querySnapshot.forEach(function (doc) {
        tmp.push(doc.data());
      });
      setAccomplishments([...tmp]);
    });
  }, []);
  return (
    <React.Fragment>
      <section id="hero_in" className="reference">
        <div className="wrapper">
          <div className="container">
            <h1 className="fadeInUp">
              Project References<span style={{ marginTop: '20px' }}></span>
            </h1>
          </div>
        </div>
      </section>

      <div className="container" style={{ marginTop: '30px' }}>
        <div className="row">
          {accomplishments.map((accomplishment, index) => (
            <div
              key={index}
              className={'col-xs-12 col-sm-12 col-md-6 col-lg-4'}
            >
              <div className="box_grid wow">
                <figure className="block-reveal">
                  <div className="block-horizzontal"></div>
                  <a
                    href={`/references/${accomplishment['EN']?.name}?lang=${lang}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={accomplishment.image?.cover}
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                  {/* <div className="preview">
                    <span>Preview</span>
                  </div> */}
                </figure>
                <div
                  className="wrapper"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(`/references/${accomplishment['EN']?.name}`);
                  }}
                >
                  <h3
                    className="text-ellipsis"
                    title={accomplishment[lang]?.name}
                  >
                    {accomplishment[lang]?.name}
                  </h3>
                  <div
                    className="text-ellipsis"
                    title={accomplishment[lang]?.description}
                  >
                    <small style={{ fontSize: '14px' }}>
                      {accomplishment[lang]?.description}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};
