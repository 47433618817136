import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ImagePreview } from '../../components/imagePreview/ImagePreview';
import { label } from '../../label';
import { languages } from '../../app.const';
import { Backdrop, CircularProgress } from '@mui/material';

export const ProductItem = ({ type, imageList }) => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || languages.TH;
  useEffect(() => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 350);
  }, [type]);
  return (
    <div>
      <h3
        style={{
          paddingLeft: '15px',
          marginBottom: '15px',
          fontWeight: 'bold',
        }}
      >
        {label.services[type][lang]}
      </h3>
      <div className="row">
        <ImagePreview imageList={imageList} />
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => setOpen(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
