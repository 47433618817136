import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AccomplishmentPage } from './pages/accomplishmentPage/AccomplishmentPage';
import { ContactPage } from './pages/contactPage/ContactPage';
import { HomePage } from './pages/homePage/HomePage';
import { Layout } from './components/layout/Layout';

import { ServicePage } from './pages/servicePage/ServicePage';
import { ProductItem } from './pages/servicePage/Product';

import { ShowPage } from './pages/showPage/ShowPage';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AdminPage } from './pages/admin/Admin';
import { LoginPage } from './pages/login/Login';
import { PrivateRoutes } from './PrivateRoutes';
import { AboutUsPage } from './pages/aboutUsPage/AboutUsPage';

const products = [
  {
    path: 'curtain-wall',
    type: 'curtainWall',
    imageList: [
      { original: '/img/products/curtainWall/1.jpg' },
      { original: '/img/products/curtainWall/2.jpg' },
      { original: '/img/products/curtainWall/3.jpg' },
      { original: '/img/products/curtainWall/4.jpg' },
      { original: '/img/products/curtainWall/5.jpg' },
      { original: '/img/products/curtainWall/6.jpg' },
      { original: '/img/products/curtainWall/7.jpg' },
      { original: '/img/products/curtainWall/8.jpg' },
      { original: '/img/products/curtainWall/9.jpg' },
      { original: '/img/products/curtainWall/10.jpg' },
    ],
  },
  {
    path: 'fixed-panel',
    type: 'fixedPanel',
    imageList: [
      { original: '/img/products/fixedPanel/1.jpg' },
      { original: '/img/products/fixedPanel/2.jpg' },
      { original: '/img/products/fixedPanel/3.jpg' },
      { original: '/img/products/fixedPanel/4.jpg' },
      { original: '/img/products/fixedPanel/5.jpg' },
      { original: '/img/products/fixedPanel/6.jpg' },
      { original: '/img/products/fixedPanel/7.jpg' },
      { original: '/img/products/fixedPanel/8.jpg' },
      { original: '/img/products/fixedPanel/9.jpg' },
      { original: '/img/products/fixedPanel/10.jpg' },
      { original: '/img/products/fixedPanel/11.jpg' },
      { original: '/img/products/fixedPanel/12.jpg' },
      { original: '/img/products/fixedPanel/13.jpg' },
      { original: '/img/products/fixedPanel/14.jpg' },
      { original: '/img/products/fixedPanel/15.jpg' },
      { original: '/img/products/fixedPanel/16.jpg' },
      { original: '/img/products/fixedPanel/17.jpg' },
    ],
  },
  {
    path: 'awing-casement-door-and-window',
    type: 'awingCasement',
    imageList: [
      { original: '/img/products/awingCasementDoorAndWindow/1.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/2.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/3.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/4.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/5.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/6.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/7.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/8.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/9.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/10.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/11.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/12.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/13.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/14.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/15.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/16.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/17.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/18.jpg' },
      { original: '/img/products/awingCasementDoorAndWindow/19.jpg' },
    ],
  },
  {
    path: 'sliding-door-and-window',
    type: 'sliding',
    imageList: [
      { original: '/img/products/slidingDoorAndWindow/1.jpg' },
      { original: '/img/products/slidingDoorAndWindow/2.jpg' },
      { original: '/img/products/slidingDoorAndWindow/3.jpg' },
      { original: '/img/products/slidingDoorAndWindow/4.jpg' },
      { original: '/img/products/slidingDoorAndWindow/5.jpg' },
      { original: '/img/products/slidingDoorAndWindow/6.jpg' },
      { original: '/img/products/slidingDoorAndWindow/7.jpg' },
      { original: '/img/products/slidingDoorAndWindow/8.jpg' },
      { original: '/img/products/slidingDoorAndWindow/9.jpg' },
      { original: '/img/products/slidingDoorAndWindow/10.jpg' },
      { original: '/img/products/slidingDoorAndWindow/11.jpg' },
      { original: '/img/products/slidingDoorAndWindow/12.jpg' },
      { original: '/img/products/slidingDoorAndWindow/13.jpg' },
    ],
  },
  {
    path: 'swing-door',
    type: 'swing',
    imageList: [
      { original: '/img/products/swingDoor/1.jpg' },
      { original: '/img/products/swingDoor/2.jpg' },
      { original: '/img/products/swingDoor/3.jpg' },
      { original: '/img/products/swingDoor/4.jpg' },
      { original: '/img/products/swingDoor/5.jpg' },
      { original: '/img/products/swingDoor/6.jpg' },
      { original: '/img/products/swingDoor/7.jpg' },
      { original: '/img/products/swingDoor/8.jpg' },
      { original: '/img/products/swingDoor/9.jpg' },
      { original: '/img/products/swingDoor/10.jpg' },
      { original: '/img/products/swingDoor/11.jpg' },
      { original: '/img/products/swingDoor/12.jpg' },
    ],
  },
  {
    path: 'frameless-door-and-window',
    type: 'frameless',
    imageList: [
      { original: '/img/products/framelessDoorAndWindow/1.jpg' },
      { original: '/img/products/framelessDoorAndWindow/2.jpg' },
      { original: '/img/products/framelessDoorAndWindow/3.jpg' },
      { original: '/img/products/framelessDoorAndWindow/4.jpg' },
      { original: '/img/products/framelessDoorAndWindow/5.jpg' },
      { original: '/img/products/framelessDoorAndWindow/6.jpg' },
      { original: '/img/products/framelessDoorAndWindow/7.jpg' },
      { original: '/img/products/framelessDoorAndWindow/8.jpg' },
      { original: '/img/products/framelessDoorAndWindow/9.jpg' },
      { original: '/img/products/framelessDoorAndWindow/10.jpg' },
      { original: '/img/products/framelessDoorAndWindow/11.jpg' },
    ],
  },
  {
    path: 'louvre',
    type: 'louvre',
    imageList: [
      { original: '/img/products/louvre/1.jpg' },
      { original: '/img/products/louvre/2.jpg' },
      { original: '/img/products/louvre/3.jpg' },
      { original: '/img/products/louvre/4.jpg' },
      { original: '/img/products/louvre/5.jpg' },
      { original: '/img/products/louvre/6.jpg' },
    ],
  },
  {
    path: 'aluminium-composite',
    type: 'aluminium',
    imageList: [
      { original: '/img/products/automaticDoorAndWindow/1.jpg' },
      { original: '/img/products/automaticDoorAndWindow/2.jpg' },
      { original: '/img/products/automaticDoorAndWindow/3.jpg' },
      { original: '/img/products/automaticDoorAndWindow/4.jpg' },
      { original: '/img/products/automaticDoorAndWindow/5.jpg' },
      { original: '/img/products/automaticDoorAndWindow/6.jpg' },
      { original: '/img/products/automaticDoorAndWindow/7.jpg' },
      { original: '/img/products/automaticDoorAndWindow/8.jpg' },
      { original: '/img/products/automaticDoorAndWindow/9.jpg' },
      { original: '/img/products/automaticDoorAndWindow/10.jpg' },
    ],
  },
  {
    path: 'railing',
    type: 'railing',
    imageList: [
      { original: '/img/products/railing/1.jpg' },
      { original: '/img/products/railing/2.jpg' },
      { original: '/img/products/railing/3.jpg' },
      { original: '/img/products/railing/4.jpg' },
      { original: '/img/products/railing/5.jpg' },
      { original: '/img/products/railing/6.jpg' },
    ],
  },
  {
    path: 'canopy',
    type: 'canopy',
    imageList: [
      { original: '/img/products/canopy/1.jpg' },
      { original: '/img/products/canopy/2.jpg' },
      { original: '/img/products/canopy/3.jpg' },
      { original: '/img/products/canopy/4.jpg' },
      { original: '/img/products/canopy/5.jpg' },
      { original: '/img/products/canopy/6.jpg' },
      { original: '/img/products/canopy/7.jpg' },
      { original: '/img/products/canopy/8.jpg' },
    ],
  },
  {
    path: 'other',
    type: 'other',
    imageList: [
      { original: '/img/products/other/1.jpg' },
      { original: '/img/products/other/2.jpg' },
      { original: '/img/products/other/3.jpg' },
      { original: '/img/products/other/4.jpg' },
      { original: '/img/products/other/5.jpg' },
    ],
  },
];

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="/" element={<Layout />}>
          <Route path="" element={<HomePage />} />
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="references" element={<AccomplishmentPage />} />
          <Route path="references/:name" element={<ShowPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="services" element={<ServicePage />} />
          <Route path="services" element={<ServicePage />}>
            {products.map((product, index) => (
              <Route
                key={index}
                path={product.path}
                element={
                  <ProductItem
                    type={product.type}
                    imageList={product.imageList}
                  />
                }
              />
            ))}
          </Route>
          <Route
            path="*"
            element={<Navigate to={`/${window.location.search}`} />}
          />
        </Route>
        <Route path="/" element={<PrivateRoutes />}>
          <Route path="admin" element={<AdminPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
