/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { label } from '../../label';
import { languages } from '../../app.const';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import './Layout.css';

export const Layout = () => {
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || languages.TH;

  useEffect(() => {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');
    const script3 = document.createElement('script');
    const script4 = document.createElement('script');
    script1.src = '/js/jquery-2.2.4.min.js';
    script2.src = '/js/common_scripts.js';
    script3.src = '/js/main.js';
    script4.src = '/assets/validate.js';
    document.getElementsByTagName('head')[0].appendChild(script1);
    document.getElementsByTagName('head')[0].appendChild(script2);
    document.getElementsByTagName('head')[0].appendChild(script3);
    document.getElementsByTagName('head')[0].appendChild(script4);
    script1.async = true;
    script2.async = true;
    script3.async = true;
    script4.async = true;
    document.head.appendChild(script1);
    document.head.appendChild(script2);
    document.head.appendChild(script3);
    document.head.appendChild(script4);
  }, []);

  return (
    <ThemeProvider theme={createTheme()}>
      <CssBaseline />
      <div id="page">
        <header className="header menu_2">
          <div id="preloader">
            <div data-loader="circle-side"></div>
          </div>
          <div id="logo" style={{ zIndex: -1 }}>
            <a href={`/?lang=${lang}`}>
              <img
                src="/img/logo-glazier.png"
                width="80"
                height="80"
                data-retina="true"
                alt=""
              />
            </a>
          </div>

          <ul id="top_menu" style={{ paddingRight: '25px' }}>
            <li>
              <a
                style={{ cursor: 'pointer' }}
                href="https://www.instagram.com/hitechglazier/"
                rel="noreferrer"
                target="_blank"
              >
                <i className="icon-instagramm"></i>
              </a>
            </li>
            <li>
              <a
                href={`?lang=${languages.TH}`}
                id="change-lang-to-th"
                style={{ cursor: 'pointer' }}
              >
                {languages.TH}
              </a>
            </li>
            <li>
              <a>|</a>
            </li>
            <li>
              <a
                href={`?lang=${languages.EN}`}
                id="change-lang-to-en"
                style={{ cursor: 'pointer' }}
              >
                {languages.EN}
              </a>
            </li>
          </ul>
          <br className="hide-md" />

          <a className="btn_mobile">
            <div className="hamburger hamburger--spin" id="hamburger">
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </a>
          <nav id="menu" className="main-menu">
            <ul>
              <li>
                <span>
                  <a href={`/${lang ? `?lang=${lang}` : ''}`}>
                    {label.nav.home[lang]}
                  </a>
                </span>
              </li>
              <li>
                <span>
                  <a href={`/about-us${lang ? `?lang=${lang}` : ''}`}>
                    {label.nav.aboutUs[lang]}
                  </a>
                </span>
              </li>
              <li>
                <span>
                  <a
                    href={`/services/curtain-wall${
                      lang ? `?lang=${lang}` : ''
                    }`}
                  >
                    {label.nav.services[lang]}
                  </a>
                </span>
                <ul>
                  <li>
                    <a
                      href={`/services/curtain-wall${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.services.curtainWall[lang]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/services/fixed-panel${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.services.fixedPanel[lang]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/services/awing-casement-door-and-window${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.services.awingCasement[lang]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/services/sliding-door-and-window${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.services.sliding[lang]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/services/swing-door${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.services.swing[lang]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/services/frameless-door-and-window${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.services.frameless[lang]}
                    </a>
                  </li>
                  <li>
                    <a href={`/services/louvre${lang ? `?lang=${lang}` : ''}`}>
                      {label.services.louvre[lang]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/services/aluminium-composite${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.services.aluminium[lang]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/services/railing${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.services.railing[lang]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/services/canopy${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.services.canopy[lang]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/services/other${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.services.other[lang]}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <span>
                  <a href={`/references${lang ? `?lang=${lang}` : ''}`}>
                    {label.nav.accomplishments[lang]}
                  </a>
                </span>
              </li>
              <li>
                <span>
                  <a href={`/contact${lang ? `?lang=${lang}` : ''}`}>
                    {label.nav.contactUs[lang]}
                  </a>
                </span>
              </li>
            </ul>
          </nav>
        </header>

        <main>
          <Outlet />
        </main>

        <footer>
          <div className="container" style={{ padding: '30px 95px' }}>
            <div className="row">
              <div className="col-lg-5 col-md-12 p-r-5">
                {/* <img
                    src="img/logo.png"
                    width="149"
                    height="42"
                    data-retina="true"
                    alt=""
                  /> */}
                <h5>Hi-Tech Glazier</h5>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  {label.contact.location[lang]}
                </p>
                <div className="follow_us">
                  <ul>
                    <li>Follow us</li>
                    {/* <li>
                      <a href="#0">
                        <i className="ti-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <i className="ti-twitter-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <i className="ti-google"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <i className="ti-pinterest"></i>
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="https://www.instagram.com/hitechglazier/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ti-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 ml-lg-auto">
                <h5>Useful links</h5>
                <ul className="links">
                  <li>
                    <a href={`/${lang ? `?lang=${lang}` : ''}`}>
                      {label.nav.home[lang]}
                    </a>
                  </li>
                  <li>
                    <a href={`/about-us${lang ? `?lang=${lang}` : ''}`}>
                      {label.nav.aboutUs[lang]}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/services/curtain-wall${
                        lang ? `?lang=${lang}` : ''
                      }`}
                    >
                      {label.nav.services[lang]}
                    </a>
                  </li>
                  <li>
                    <a href={`/refferences${lang ? `?lang=${lang}` : ''}`}>
                      {label.nav.accomplishments[lang]}
                    </a>
                  </li>
                  <li>
                    <a href={`/contact${lang ? `?lang=${lang}` : ''}`}>
                      {label.nav.contactUs[lang]}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6">
                <h5>Contact with Us</h5>
                <ul className="contacts">
                  <li>
                    <a href="tel://025095111">
                      <i className="ti-mobile"></i> 02-509-5111
                    </a>
                  </li>
                  <li>
                    <a href="mailto:ht.lucky24@gmail.com">
                      <i className="ti-email"></i> ht.lucky24@gmail.com
                    </a>
                  </li>
                </ul>
                {/* <div id="newsletter">
                  <h6>Newsletter</h6>
                  <div id="message-newsletter"></div>
                  <form
                    method="post"
                    action="assets/newsletter.php"
                    name="newsletter_form"
                    id="newsletter_form"
                  >
                    <div className="form-group">
                      <input
                        type="email"
                        name="email_newsletter"
                        id="email_newsletter"
                        className="form-control"
                        placeholder="Your email"
                      />
                      <input
                        type="submit"
                        value="Submit"
                        id="submit-newsletter"
                      />
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-8">
                <ul id="additional_links">
                  <li>
                    <a href="#0">Terms and conditions</a>
                  </li>
                  <li>
                    <a href="#0">Privacy</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <div id="copy">© Hi-Tech Glazier</div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </ThemeProvider>
  );
};
