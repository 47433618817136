export const label = {
  nav: {
    home: {
      TH: 'หน้าหลัก',
      EN: 'HOME',
    },
    aboutUs: {
      TH: 'เกี่ยวกับเรา',
      EN: 'About Us',
    },
    services: {
      TH: 'สินค้าและบริการ',
      EN: 'Product & Services',
    },
    accomplishments: {
      TH: 'ผลงาน',
      EN: 'Reference',
    },
    contactUs: {
      TH: 'ติดต่อเรา',
      EN: 'Contact Us',
    },
  },
  services: {
    curtainWall: {
      TH: 'ระบบผนังโครงสร้าง',
      EN: 'Curtain Wall',
    },
    fixedPanel: {
      TH: 'ผนังกระจก',
      EN: 'Fixed Panel',
    },
    awingCasement: {
      TH: 'บานกระทุ้ง/บานเปิด',
      EN: 'Awing/Casement \nDoor and Window',
    },
    sliding: {
      TH: 'บานเลื่อน',
      EN: 'Sliding \nDoor and Window',
    },
    swing: {
      TH: 'บานสวิง',
      EN: 'Swing Door',
    },
    frameless: {
      TH: 'บานเปลือย',
      EN: 'Frameless \nDoor and Window',
    },
    louvre: {
      TH: 'บานเกล็ด',
      EN: 'Louvre',
    },
    aluminium: {
      TH: 'ประตูอัตโนมัติ',
      EN: 'Automatic Door',
    },
    railing: {
      TH: 'ราวกันตก',
      EN: 'Railing',
    },
    canopy: {
      TH: 'หลังคากระจก',
      EN: 'Canopy',
    },
    other: {
      TH: 'อื่นๆ',
      EN: 'other',
    }
  },
  homepage: {
    h1: {
      TH: 'H1',
      EN: 'H1',
    },
    h2: {
      TH: 'H2',
      EN: 'H2',
    },
    h3: {
      TH: 'H3',
      EN: 'H3',
    },
    properties: {
      p1: {
        header: {
          TH: 'properties.p1',
          EN: 'properties.p1',
        },
        description: {
          TH: 'properties.p1',
          EN: 'properties.p1',
        },
      },
      p2: {
        header: {
          TH: 'properties.p2',
          EN: 'properties.p2',
        },
        description: {
          TH: 'properties.p2',
          EN: 'properties.p2',
        },
      },
      p3: {
        header: {
          TH: 'properties.p3',
          EN: 'properties.p3',
        },
        description: {
          TH: 'properties.p3',
          EN: 'properties.p3',
        },
      },
    },
  },
  contact: {
    location: {
      TH: 'บริษัท ไฮเทค เกลซเซอร์ จำกัด\nเลขที่ 9,10/1 ซอยรามอินทรา14 แยก1\nถนนรามอินทรา แขวงท่าแร้ง เขตบางเขน\nกรุงเทพฯ 10230',
      EN: 'Hi-Tech Glazier Co., Ltd.\n9,10/1 Soi Ramintra 14 Yaek 1,\nRamintra Road, Tharang Subdistrict, BangKhen District,\nBangkok 10230',
    },
    phone: {
      TH: 'โทร: 02-509-5111, 02-510-2888',
      EN: 'Phone: 02-509-5111, 02-510-2888',
    },
  },
  aboutUs: {
    first: {
      TH: 'บริษัท ไฮเทค เกลซเซอร์ จำกัด จดทะเบียนก่อตั้งขึ้นในปี พ.ศ. 2535 เราดำเนินการธุรกิจรับติดตั้ง ผลิตภัณฑ์ประตูหน้าต่างอลูมิเนียมและกระจก รวมถึงให้บริการ จัดจำหน่ายอลูมิเนียมและกระจกหลากหลายประเภท ไม่ว่าจะเป็นรูปแบบ ขนาด หรือสีสันที่หลากหลาย เพื่อตอบสนองความต้องการของลูกค้าในด้านความสวยงาม การใช้งานสะดวก ราคาเหมาะสม และที่ขาดไปไม่ได้คือความปลอดภัย',
      EN: 'Hi-Tech Glazier Co., Ltd. established in 1992. We offer aluminium and glass doors/windows works including installation and distribution. With various types of patterns, size, or colour of aluminium and glass, we certainly provide the best quality we do to suit your individual and/or business purposes in design, easy to use, reasonable price, and especially safe usage.',
    },
    second: {
      TH: 'จากผลงานตลอด 30 ปีที่ผ่านมาทั้งภาครัฐและเอกชน บริษัท ไฮเทค เกลซเซอร์ มีทีมงานช่างบริการและทีมงานผู้เชียวชาญที่มีประสบการณ์กว่า 30 ปี อีกทั้ง เรายังมีคู่ค้าทางธุรกิจ ทั้งโรงผลิต โรงแปลรูปกระจก โรงรีด โรงชุบและโรงพ่นสีอลูมิเนียม ที่มีคุณภาพระดับแนวหน้า จึงทำให้เรามั่นใจในผลงานของเราและ ลูกค้ายังคงมั่นใจและเชื่อใจในผลงานของเราเสมอมา',
      EN: 'From our works of the past 30 years, with public and private sectors, Hi-Tech Glazier has skilled and professional experts with over 30 years of experience. We also have the high-standard business partners in all aspects of this industry where we efficiently operate with glass manufacturer, glass processing plant, glass rolling plant, aluminium plating and spraying manufacturer. We are therefore highly certain and confident in our works which are always trust and satisfied by our valued customers.',
    },
  },
};
