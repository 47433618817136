import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { languages } from '../../app.const';
import { label } from '../../label';
import './AboutUsPage.css';

export const AboutUsPage = () => {
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') || languages.TH;

  return (
    <div className={`about-us ${lang === languages.EN ? 'en' : ''}`}>
      <section className="hero_single">
        <div className="wrapper">
          <div className="container">
            <div style={{ position: 'relative' }}>
              <div>
                <h3 style={{ textAlign: 'center', fontSize: '2.5rem' }}>
                  {label.nav.aboutUs[lang]}
                </h3>
                <p
                  style={{
                    textAlign: 'justify',
                    textIndent: '30px',
                    padding: '0 5%',
                    margin: '30px 0',
                  }}
                >
                  {label.aboutUs.first[lang]}
                </p>
                <p
                  style={{
                    textAlign: 'justify',
                    textIndent: '30px',
                    padding: '0 20px',
                    margin: '0 10%',
                    borderLeft: 'solid white 5px',
                  }}
                >
                  {label.aboutUs.second[lang]}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
