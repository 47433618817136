import React, { useRef, useState } from 'react';
import { Card, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import ImageGallery from 'react-image-gallery';
import './ImagePreview.css';

export const ImagePreview = ({ imageList }) => {
  const [open, setOpen] = useState(false);
  const gallery = useRef(null);

  const handlePreview = (index) => {
    setOpen(true);
    if (gallery && gallery.current) {
      const tmp = gallery.current;
      tmp.slideToIndex(index);
    } else {
      //case firstload
      setTimeout(() => {
        const tmp = gallery.current;
        tmp.slideToIndex(index);
      }, 100);
    }
  };

  return (
    <React.Fragment>
      {imageList?.map((image, index) => (
        <div
          key={index}
          className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4"
        >
          <Card
            style={{ cursor: 'pointer', marginBottom: '30px' }}
            onClick={() => {
              handlePreview(index);
            }}
          >
            <div
              style={{
                backgroundImage: `url(${image.original})`,
                height: '300px',
                display: 'block',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
          </Card>
          <Modal sx={{ zIndex: 99999, backgroundColor: 'black' }} open={open}>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <Close
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  cursor: 'pointer',
                  fontSize: '36px',
                  zIndex: 99999,
                  color: 'white',
                  filter: 'drop-shadow(0 2px 2px #1a1a1a)',
                }}
                onClick={() => {
                  setOpen(false);
                }}
              />
              <ImageGallery
                showPlayButton={false}
                ref={gallery}
                items={imageList}
              />
            </div>
          </Modal>
        </div>
      ))}
    </React.Fragment>
  );
};
