import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCggzZADwKwTSx6UWzfDHRDv_mNl7UzyX4',
  authDomain: 'hi-tech-glazier.firebaseapp.com',
  projectId: 'hi-tech-glazier',
  storageBucket: 'hi-tech-glazier.appspot.com',
  messagingSenderId: '740900491403',
  appId: '1:740900491403:web:bd78d25ac0013e4d1e848e',
};

// const firebaseConfig = {
//   apiKey: 'AIzaSyCXrxfD8V6xH49U-jc3PO4FApS5ywrP-hs',
//   authDomain: 'glazier-test.firebaseapp.com',
//   databaseURL: 'https://glazier-test.firebaseio.com',
//   projectId: 'glazier-test',
//   storageBucket: 'glazier-test.appspot.com',
//   messagingSenderId: '290408961323',
//   appId: '1:290408961323:web:bf7a23daab51bc8ceba16e',
// };

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const accomplishmentCollection = db.collection('accomplishment');

export const getAccomplishmentPromise = () => {
  return accomplishmentCollection.orderBy('timestamp', 'desc').get();
};

export const getAccomplishmentByNamePromise = (name) => {
  return accomplishmentCollection.where('EN.name', '==', name).get();
};

export const addAccomplishment = (data) => {
  return accomplishmentCollection.add(data);
};

export const login = (email, password) => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
};

export const logout = () => {
  const auth = getAuth();
  return signOut(auth);
};
